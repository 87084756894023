import React, { useState, useEffect } from "react";
import Header from "../components/header/Header";
import heroSliderData from "../assets/fake-data/data-slider";
import Slider from "../components/slider/Slider";
import SliderStyle2 from "../components/slider/SliderStyle2";
import StartNow from "../components/layouts/StartNow";
//import ExitModal from "../components/exit-chance/ExitModal";
import DisclaimerModal from "../components/disclaimer/DisclaimerModal";

import { useWeb3Context } from "../contexts/Web3Context";
import { usePlayerContext } from "../contexts/PlayerContext";
import { getHotIds } from "../config/resources/13-hot-nfts";

const BEAST_COUNT = 40;

const Home01 = () => {
  const { isReady } = useWeb3Context();
  const { fetchBeasts } = usePlayerContext();
  const [beastIds, setBeastIds] = useState([]);
  const [beasts, setBeasts] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const getWidth = () => {
    const width = window.innerWidth;
    return width;
  };

  useEffect(() => {
    const skipModalPref = localStorage.getItem("skipModal_3");
    if (!skipModalPref) {
      setShowModal(true);
    }

    if (getWidth() < 720) return;
    if (!isReady) return;

    if (beastIds?.length > 0 || beasts?.length > 0) {
      return;
    }

    const _beastIds = getHotIds(BEAST_COUNT);
    setBeastIds(_beastIds);

    const doFetchBeasts = async (_beastIds) => {
      console.log("Home :: fetching beasts...");

      let _beasts = [];
      let error = false;
      try {
        _beasts = await fetchBeasts(_beastIds);
      } catch (e) {
        console.error("Home :: error fetching beasts", e);
        _beasts = [];
        error = true;
      }

      if (_beasts && _beasts?.length > 0 && !error) {
        try {
          setBeasts(_beasts);
        } catch (e) {
          console.error("Home :: error setting beasts", e);
        }
      } else {
        console.warn("no beasts");
      }
    };
    doFetchBeasts(_beastIds);
  }, [isReady]);

  return (
    <>
      <div className={beasts ? "home-5" : "home-1"}>
        {showModal && <DisclaimerModal />}
        <Header />
        {(!beasts || getWidth() < 720) && <Slider data={heroSliderData} />}
        {beasts?.length > 0 && getWidth() >= 720 && <SliderStyle2 beasts={beasts} />}
      </div>
    </>
  );
};

export default Home01;
