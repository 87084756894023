import KingdomFlowJson from "../../contracts/features/KingdomFlow.sol/KingdomFlow.json";
import KingdomFundsJson from "../../contracts/features/KingdomFunds.sol/KingdomFunds.json";
import KingdomNoblesJson from "../../contracts/features/KingdomNobles.sol/KingdomNobles.json";
import KoziPoolJson from "../../contracts/features/KoziPool.sol/KoziPool.json";
import BkCosmeticsJson from "../../contracts/features/BkCosmetics.sol/BkCosmetics.json";
import BkForgeJson from "../../contracts/features/BkForge.sol/BkForge.json";
import BkHighUpgradesJson from "../../contracts/features/BkHighUpgrades.sol/BkHighUpgrades.json";
import BkVaultJson from "../../contracts/features/BkVaultV2/BkVaultV2.sol/BkVaultV2.json";
import BkBoosterCrackerJson from "../../contracts/features/BkBoosterCracker.sol/BkBoosterCracker.json";
import BkTraitRerollerJson from "../../contracts/features/BkTraitReroller.sol/BkTraitReroller.json";
import QuestManagerJson from "../../contracts/features/BkQuestManager.sol/BkQuestManager.json";
import BkFetchJson from "../../contracts/features/BkFetch.sol/BkFetch.json";
import BkDnaManagerJson from "../../contracts/features/BkDnaManager.sol/BkDnaManager.json";
import BkMarketplaceJson from "../../contracts/features/BkMarketplace.sol/BkMarketplace.json";
import BkFarmJson from "../../contracts/features/BkFarm.sol/BkFarm.json";
import BkQuestRewardsCalculatorJson from "../../contracts/features/BkQuestRewardsCalculator.sol/BkQuestRewardsCalculator.json";
import BkUpgradesJson from "../../contracts/features/BkUpgrades.sol/BkUpgrades.json";
import BkPlayerBonusJson from "../../contracts/features/BkPlayerBonus.sol/BkPlayerBonus.json";
import CommunityPoolJson from "../../contracts/features/CommunityPool.sol/CommunityPool.json";
import BkStasherJson from "../../contracts/features/BkStasher.sol/BkStasher.json";
import BkNftJson from "../../contracts/erc721/BkNft.sol/BkNft.json";
import ExternalERC20Json from "../../contracts/erc20/ExternalERC20.sol/ExternalERC20.json";
import NativeERC20Json from "../../contracts/erc20/NativeERC20.sol/NativeERC20.json";
import EasyBkCharLibJson from "../../contracts/erc721/EasyBkChar.sol/EasyBkChar.json";
import BlocklistJson from "../../contracts/features/Blocklist.sol/Blocklist.json";
//import BatchGiverJson from "../../contracts/features/BkBatchGiver.sol/BkBatchGiver.json";
import BkCommonwealthFundJson from "../../contracts/features/BkCommonwealthFund.sol/BkCommonwealthFund.json";
import BkDnaMasterJson from "../../contracts/features/BkDnaMaster.sol/BkDnaMaster.json";
import BkMigratorJson from "../../contracts/features/BkMigrator.sol/BkMigrator.json";
import KingdomBridgeJson from "../../contracts/features/KingdomBridge/KingdomBridge/KingdomBridge.sol/KingdomBridge.json";
import BnbBridgeJson from "../../contracts/features/KingdomBridge/BnbBridge/BnbBridge.sol/BnbBridge.json";
import KoziPaymentsJson from "../../contracts/features/KoziPayments.sol/KoziPayments.json";
import BkBatchJson from "../../contracts/features/BkBatch.sol/BkBatch.json";
import DelayedRandomResultJson from "../../contracts/features/DelayedRandomResult.sol/DelayedRandomResult.json";
import FurAndFortuneJson from "../../contracts/features/FurAndFortune.sol/FurAndFortune.json";
import EntropyGeneratorJson from "../../contracts/features/EntropyGenerator.sol/EntropyGenerator.json";
import GravedanceJson from "../../contracts/features/BkGravedance.sol/BkGravedance.json";
import QuestMathJson from "../../contracts/features/BkQuestMath.sol/BkQuestMath.json";
import BkCypherpunksJson from "../../contracts/features/BkCypherpunks.sol/BkCypherpunks.json";
import CosmicFundJson from "../../contracts/features/CosmicFund.sol/CosmicFund.json";
import LandOwnersFundJson from "../../contracts/features/CosmicFund.sol/CosmicFund.json";

const prod = {
  name: "KINGDOM CHAIN Mainnet",
  chainId: 39916801,
  validators: ["0x00000871604FeC3BAf42e134171A018B8dD2A6A6"],
  operator: "0x00000871604FeC3BAf42e134171A018B8dD2A6A6",
  treasury: "0x44710845C6f4A075495672607cd858FA9cE73f05",
  LandOwnersFund: {
    address: "0xb0057b409F326278Ed82Fe9354EEcAf0e98c0834",
    name: "Land Owners Fund",
    abi: CosmicFundJson.abi,
  },
  CosmicFund: {
    address: "0xc2Feee84c33d9529dA899c6456E1AB39990965d5",
    name: "Cosmic Fund",
    abi: CosmicFundJson.abi,
  },
  BkCypherpunks: {
    address: "0x0621df238BA88EE7773Eb78aeb5811F013ECd756",
    name: "BK Cypherpunks",
    abi: BkCypherpunksJson.abi,
  },
  QuestMath: {
    address: "0xcaB7B6E593974ee0636Aec7B7708C513353D2593",
    name: "BK Quest Math",
    abi: QuestMathJson.abi,
  },
  Gravedance: {
    address: "0x1E0B9c2E34BfD49df101a5c3a5e2d3142E161C0F",
    name: "BK Gravedance",
    abi: GravedanceJson.abi,
  },
  EntropyGenerator: {
    address: "0xBd0E1157c9B9B8Ea26B8FAaf3BC0dfb2d584d3A7",
    name: "Entropy Generator",
    abi: EntropyGeneratorJson.abi,
  },
  FurAndFortune: {
    address: "0x9567ff47970Eaf24b64873a75Dd75D616EaB8352",
    name: "Fur And Fortune",
    abi: FurAndFortuneJson.abi,
  },
  DelayedRandomResult: {
    address: "0x848E3C120BEa61433f5e4A7822c8605BC6e19532",
    name: "Delayed Random Result",
    abi: DelayedRandomResultJson.abi,
  },
  BkBatch: {
    address: "0x57Af170a76d3C183078ed491fdf5479B90f91Ac9",
    name: "Bk Batch",
    abi: BkBatchJson.abi,
  },
  KoziPayments: {
    address: "0xe9df288440497fD7214ea4BBf889a1865E779Ab9",
    name: "Kozi Payments",
    abi: KoziPaymentsJson.abi,
  },
  DnaMaster: {
    address: "0x4F681B3aAC9b5c345807554e9B9f95B4da38ef9E",
    name: "BK Dna Master",
    abi: BkDnaMasterJson.abi,
  },
  BkMigrator: {
    address: "0x235dED1354678A4E96266Bc4e9E70085855B7b59",
    name: "BK Migrator",
    abi: BkMigratorJson.abi,
  },
  BkCommonwealthFund: {
    address: "0x285f86DE2A3B9441911a377507810a1D57006722",
    name: "Commowealth Fund",
    abi: BkCommonwealthFundJson.abi,
  },
  KingdomBridge: {
    address: "0x8A2a269B0b14E888189fF1C61339aCF2cF62F447",
    name: "Kingdom Bridge",
    abi: KingdomBridgeJson.abi,
  },
  /*
  BatchGiver: {
    address: "0xaA40997DA08B3c945D9CCf13fd2F685EB6bD7A3b",
    name: "Batch Giver",
    abi: BatchGiverJson.abi,
  },
  */
  KingdomFlow: {
    address: "0x0b1a78aC2f1c29e02866bB102d6F74078c066F22",
    name: "Kingdom Flow",
    abi: KingdomFlowJson.abi,
  },
  KingdomFunds: {
    address: "0xcd349f6Fa48AaF08a6B2A08509dd5EF8F669397c",
    name: "Kingdom Funds",
    abi: KingdomFundsJson.abi,
  },
  KingdomNobles: {
    address: "0x718514Ec465A59C78E703C9aF91545290d0020B5",
    name: "Kingdom Nobles",
    abi: KingdomNoblesJson.abi,
  },
  KoziPool: {
    address: "0xcE0C786a408a6D7C58c98b06f04Efdce7b5AAa65",
    name: "Kozi Pool",
    abi: KoziPoolJson.abi,
  },
  Cosmetics: {
    address: "0x5F98cCFE4Bb8C16385D4C5ba6aE5BC9a252C629A",
    name: "BK Cosmetics",
    abi: BkCosmeticsJson.abi,
  },
  Forge: {
    address: "0xC00610311A483E550064877B9825e42DB23bb814",
    name: "BK Forge",
    abi: BkForgeJson.abi,
  },
  HighUpgrades: {
    address: "0x09aBBcD3d251E5C6DE398E4BCF12Eebc887a7a30",
    name: "BK High Upgrades",
    abi: BkHighUpgradesJson.abi,
  },
  Vault: {
    address: "0x07531293525d4c100b58AA9EaD97dc9568F85270",
    name: "BK Vault",
    abi: BkVaultJson.abi,
  },
  BoosterCracker: {
    address: "0x4a2e0AA4d5FbE40C6e211cf47152e71F8080EB06",
    name: "BK Booster Cracker",
    abi: BkBoosterCrackerJson.abi,
  },
  TraitReroller: {
    address: "0x7bD4b5df0BDEb9f9572FBac353937CB8A7b6eD40",
    name: "BK Trait Reroller",
    abi: BkTraitRerollerJson.abi,
  },
  QuestManager: {
    address: "0x12E7baf355a98DBa551F15a945B5cBe36d6022Cf",
    name: "BK Quest Manager",
    abi: QuestManagerJson.abi,
  },
  Fetch: {
    address: "0x7abf363557cB42376F8B0ecfa9bdd42FC21eea64",
    name: "BK Fetch",
    abi: BkFetchJson.abi,
  },
  DnaManager: {
    address: "0x1f4FBC4fC9Af0939C2CDBabc2F114CF17a367390",
    name: "BK Dna Manager",
    abi: BkDnaManagerJson.abi,
  },
  Marketplace: {
    address: "0xe4AF18e0bB5d6d3c79A5FEaeccD5573dD156ca4A",
    name: "BK Marketplace",
    abi: BkMarketplaceJson.abi,
  },
  Farm: {
    address: "0x4b74c298DAaF714d5775632B22029644ac38B1De",
    name: "BK Farm",
    abi: BkFarmJson.abi,
  },
  QuestRewardsCalculator: {
    address: "0xF8578B44990B76cBB651AC30952F587788aB1Af2",
    name: "BK Quest Rewards Calculator",
    abi: BkQuestRewardsCalculatorJson.abi,
  },
  Upgrades: {
    address: "0x7a8C0Bf749E1B364314Fe06A9587622435D28094",
    name: "BK Upgrades",
    abi: BkUpgradesJson.abi,
  },
  PlayerBonus: {
    address: "0x9758300ac51e5b96F47c4aE73fc904FD1077276F",
    name: "BK Player Bonus",
    abi: BkPlayerBonusJson.abi,
  },
  CommunityPool: {
    address: "0x01A1F3338aFea3155D0840579506ea230398F83c",
    name: "BK Community Pool",
    abi: CommunityPoolJson.abi,
  },
  Stasher: {
    address: "0x2371c034Dc5Fc62C1347557e885616d7390a9D79",
    name: "BK Stasher",
    abi: BkStasherJson.abi,
  },
  BkNft: {
    address: "0x3bda2079ADe82e275EE3FA9B886203e44A6c5a41",
    name: "BK Beast NFT",
    abi: BkNftJson.abi,
  },
  Stabletoken: {
    name: "USDC - Kingdom-Peg USD Coin",
    symbol: "USDC",
    decimals: 18,
    address: "0xda4f69a9DBe19b53569FC5dcdE02d93d980B596C",
    abi: ExternalERC20Json.abi,
  },
  BCT: {
    name: "BCT - Beast Community Token",
    symbol: "BCT",
    decimals: 18,
    address: "0x150239ed00310bC37798D5d12A76F17758814326",
    abi: NativeERC20Json.abi,
  },
  MathLib: {
    address: "0x748349b1723082Ba328E3B5C01eA5eB4E7E59e48",
    name: "Maths",
    abi: [],
  },
  EasyBkCharLib: {
    address: "0x8Fc7245939acBfe9dc2909Cc4bcDDFaD895157d7",
    name: "BK EasyBkCharLib",
    abi: EasyBkCharLibJson.abi,
  },
  RResult: {
    address: "0x85bDac8e38eD827ffE1BCE009dC1EB2155314C65",
    core: "0xefe3e76E354C26c1f1096d6EE87FbB876CD8eeB9",
    name: "BK RResult",
    abi: [],
  },
  Blocklist: {
    address: "0x63D4709F184Fe352c6176E6Cc13Db66517C52A7B",
    name: "BK Blocklist",
    abi: BlocklistJson.abi,
  },
  Blackhole: {
    address: "0x0000000000000000000000000000000000000000",
    name: "Zero Address",
    abi: [],
  },
  BnbBridge: {
    address: "0xda4f69a9DBe19b53569FC5dcdE02d93d980B596C",
    name: "Bnb Bridge",
    abi: BnbBridgeJson.abi,
    note: "This contract is not deployed on the Kingdom Chain. Instead, it is on the BNB Chain.",
  },
};

export default prod;
