import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/header/Header.jsx";
import { IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } from "../utils/environment.js";

const TrophiesAndTickets = () => {
  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">🏆</h1>
                    <h1 className="heading text-center">Trophies & Tickets</h1>
                  </div>
                  <div>
                    <h5 className="sub-title help-center mg-bt-32 ">
                      Trophies (🏆) and Tickets (🎫) are valuable assets in our ecosystem, providing players with unique
                      advantages and opportunities.
                    </h5>

                    <h5 className="sub-title help-center" style={{ color: "yellow" }}>
                      Previously, Lucky Tickets were earned through Kozi Holders ranking
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      Players gained Lucky Tickets based on their position in the Kozi Holders ranking, earning more
                      tickets the closer they were to the King.
                    </h5>

                    <h5 className="sub-title help-center" style={{ color: "yellow" }}>
                      Prestige Trophies were awarded for spending Kozi
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      Every time a player spent 0.1 or more Kozi on our website, they were awarded 10% of that amount in
                      Prestige Trophies. For example, spending 1 Kozi earned 0.1 Prestige Trophies.
                    </h5>

                    <h5 className="sub-title help-center" style={{ color: "yellow" }}>
                      A new chapter for Trophies and Tickets
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      Moving forward, Trophies and Tickets will be part of the Cosmic experience. They can provide some
                      advantages, access to occasional offers, and unlock rewards. However, until Cosmic launches,
                      there is no way to earn new Trophies or Tickets. You can still use them in Beast Kingdom.
                    </h5>

                    <h5 className="sub-title help-center" style={{ color: "yellow" }}>
                      You can still use them in Beast Kingdom
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      Players can continue spending Lucky Tickets and Prestige Trophies in the{" "}
                      <Link to="/fur-and-fortune" style={{ textDecorationLine: "underline" }}>
                        Fur & Fortune
                      </Link>{" "}
                      game to win substantial prizes. New ways to earn Trophies and Tickets will be introduced in Cosmic
                      once it launches.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="tf-help-center tf-section">
            <div className="themesflat-container">
              <div className="row">
                <div className="col-12">
                  <h2 className="tf-title-heading ct style-2 fs-30 mg-bt-10"></h2>
                  <h5 className="sub-title help-center mg-bt-32 "></h5>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <h2 className="tf-title-heading ct style-2 fs-30 mg-bt-10" style={{ paddingTop: "200px" }}>
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default TrophiesAndTickets;
