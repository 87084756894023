import React from "react";
import BigNumber from "bignumber.js";
import { Tooltip } from "react-tooltip";

import Crown1 from "../../assets/images/nobles/crown-1.png";
import Crown2 from "../../assets/images/nobles/crown-2.png";
import Crown3 from "../../assets/images/nobles/crown-3.png";
import Crown4 from "../../assets/images/nobles/crown-4.png";
import Crown5 from "../../assets/images/nobles/crown-5.png";
import Separator from "../../assets/images/icon/separator.png";

import {
  GlobalContainer,
  Container,
  NobleContainer,
  NobleName,
  NobleTitle,
  NobleNameContainer,
} from "./styles";

import { NOBLE_TITLES } from "../../config/resources/16-kingdomNobles";

const KingdomNoblesRanking = ({ nobles = [] }) => {
  const shareEmoji = (share) => {
    if (share == 3.8) {
      return "🍖";
    } else if (share >= 1) {
      return "🥩";
    } else if (share >= 0.2) {
      return "🍗";
    } else if (share >= 0.1) {
      return "🌮";
    } else {
      return "🥪";
    }
  };

  return (
    <GlobalContainer>
      {nobles.length > 0 && (
        <Container>
          {Array.from(nobles)?.map((noble, index) => (
            <>
              {index === 0 ? (
                <img
                  src={Crown1}
                  alt="crown"
                  style={{
                    width: "330px",
                    position: "relative",
                    right: "1px",
                    marginBottom: "-10px",
                  }}
                />
              ) : index >= 1 && index <= 3 ? (
                <img
                  src={Crown2}
                  alt="crown"
                  style={{
                    width: "325px",
                    position: "relative",
                    marginBottom: "-12px",
                  }}
                />
              ) : index >= 4 && index <= 9 ? (
                <img
                  src={Crown3}
                  alt="crown"
                  style={{
                    width: "328px",
                    position: "relative",
                    marginBottom: "-5px",
                  }}
                />
              ) : index >= 10 && index < 15 ? (
                <img
                  src={Crown4}
                  alt="separator"
                  style={{
                    width: "330px",
                    marginBottom: "-5px",
                    marginTop: "10px",
                  }}
                />
              ) : (
                <img
                  src={Crown5}
                  alt="separator"
                  style={{
                    width: "330px",
                    marginBottom: "-4px",
                    marginTop: "-24px",
                  }}
                />
              )}
              <NobleContainer key={index} position={index + 1}>
                {index === 0 ? (
                  <NobleTitle name={noble.name} pos={1}>
                    <span style={{ color: "#fc0" }}>
                      👑 1. {noble.name ? `King ${noble.name} 👑` : "The King"}
                    </span>
                  </NobleTitle>
                ) : (
                  <NobleTitle name={noble.name} pos={index + 1}>
                    <span
                      style={
                        noble.name
                          ? index <= 3
                            ? { color: "#CC0" }
                            : { color: "#ddd" }
                          : { color: "#666" }
                      }
                    >
                      {index + 1}. {NOBLE_TITLES[index] || "Noble"}{" "}
                      {noble.name ? `${noble.name}` : ""}
                    </span>
                  </NobleTitle>
                )}
                <NobleNameContainer>
                  <a data-tooltip-id={index + "kozi"} data-tooltip-content={"Kozi balance"}>
                    <NobleName name={noble.name}>{BigNumber(noble.kozi).toFixed(2)} 🧿</NobleName>
                  </a>
                  <Tooltip
                    id={index + "kozi"}
                    style={{ fontSize: "12px", zIndex: 99999, fontWeight: 600 }}
                  />

                  
                  <Tooltip
                    id={index + "pct"}
                    style={{ fontSize: "12px", zIndex: 99999, fontWeight: 600 }}
                  />

                  <a data-tooltip-id={index + "loyalty"} data-tooltip-content={"Lucky Tickets"}>
                    <NobleName name={noble.name}>{(noble.loyalty / 2000).toFixed(2)} 🎫</NobleName>
                  </a>
                  <Tooltip
                    id={index + "loyalty"}
                    style={{ fontSize: "12px", zIndex: 99999, fontWeight: 600 }}
                  />

                  <a
                    data-tooltip-id={index + "prestigeTrophy"}
                    data-tooltip-content={"Prestige Trophies"}
                  >
                    <NobleName name={noble.name}>{noble.prestigeTrophies.toFixed(2)} 🏆</NobleName>
                  </a>
                  <Tooltip
                    id={index + "prestigeTrophy"}
                    style={{ fontSize: "12px", zIndex: 99999, fontWeight: 600 }}
                  />
                </NobleNameContainer>
              </NobleContainer>
            </>
          ))}
        </Container>
      )}
    </GlobalContainer>
  );
};

export default KingdomNoblesRanking;
